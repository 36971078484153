/* Section Container */
.servicesSection {
    padding: 80px 20px;
    background-color: #f8f9fa;  /* Light gray background for simplicity */
    text-align: center;
  }
  
  .servicesTitle {
    font-size: 3rem;
    font-weight: bold;
    color: #343a40;  /* Dark gray text color for contrast */
    margin-bottom: 50px;
  }
  
  /* Services Grid */
  .servicesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Service Card */
  .serviceCard {
    background-color: white;  /* Clean white background */
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);  /* Softer shadow for minimal look */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .serviceCard:hover {
    transform: translateY(-10px);  /* Simple lift effect */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);  /* Stronger shadow on hover */
  }
  
  /* Service Icon */
  .serviceIcon {
    font-size: 3rem;
    margin-bottom: 20px;
    color: #343a40;  /* Uniform dark color for all icons */
  }
  
  /* Service Name */
  .serviceName {
    font-size: 1.75rem;
    font-weight: 600;
    color: #343a40;
  }
  
  /* Service Description */
  .serviceDescription {
    font-size: 1rem;
    color: #6c757d;
    margin-top: 10px;
    line-height: 1.5;
  }
  
  /* Responsive Grid */
  @media (max-width: 768px) {
    .servicesTitle {
      font-size: 2.5rem;
    }
  
    .servicesGrid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }
  